import "../styles/layouts/news.scss";

import { graphql, PageProps } from "gatsby";
import React from "react";

import { wavePAQs_svg } from "../assets/svg/home_elements";
import HeroNews from "../components/Hero/HeroNews/HeroNews";
import Layout from "../components/Layout/layout";
import CategoriesNews, { CategoriesLink } from "../components/News/CategoriesNews/CateroriesNews";
import GridPosts from "../components/News/GridPosts/GridPosts";
import { PostResume } from "../components/News/types";
import Pagination from "../components/Pagination/Pagination";
import { SEO } from "../components/SEO/SEO";
import SubscribeNewsletter from "../components/SubscribeNewsletter/SubscribeNewsletter";
import { Problem } from "../components/WeKnowYourProblems/ProblemCard/ProblemCard";
import WeKnowYourProblems from "../components/WeKnowYourProblems/WeKnowYourProblems";
import WeTalk from "../components/WeTalk/WeTalk";
import { Photo, Slug } from "../types/shared";

interface CatsPageProps extends PageProps<Queries.PageCategoriesQuery> {
  pageContext: {
    humanPageNumber: number;
    limit: number;
    skip: number;
    id: string;
    nextPagePath: string;
    numberOfPages: number;
    previousPagePath: string;
  };
}

const PageNews = ({ data, pageContext }: CatsPageProps) => {
  const postList: PostResume[] = [];

  data.posts.nodes.forEach((node) => {
    postList.push({
      _id: node._id as string,
      category: node.category?.name as string,
      date: node.publishedAt as string,
      resume: node.seo.description,
      slug: node.slug as Slug,
      thumbnail: node.hero?.photo as Photo,
      title: node.hero?.title as string,
    });
  });

  return (
    <Layout>
      <div className="l-news">
        <HeroNews
          title={data.sanityTemplateCategories?.hero?.title}
          photo={data.sanityTemplateCategories?.hero?.photo as unknown as Photo}
        />
        <CategoriesNews categoriesLinkList={data.categories.nodes as CategoriesLink[]} />
        <GridPosts postsList={postList} />
        <Pagination
          nextPagePath={pageContext.nextPagePath}
          numberOfPages={pageContext.numberOfPages}
          pageNumber={pageContext.humanPageNumber}
          previousPagePath={pageContext.previousPagePath}
        />
        <WeKnowYourProblems
          title={data.sanityPageHome?.weKnowYourProblems?.title}
          problemsList={data.sanityPageHome?.weKnowYourProblems?.PAQsList as unknown as Problem[]}
        />
        <div className="wavePAQs_svg">{wavePAQs_svg}</div>
        <WeTalk />
        <SubscribeNewsletter />
      </div>
    </Layout>
  );
};

export default PageNews;

export const Head = ({ pageContext, data }: CatsPageProps) => {
  if (pageContext.humanPageNumber > 1) {
    return (
      <SEO
        title={`Página ${pageContext.humanPageNumber} | ${data.sanityTemplateCategories?.seo?.title}`}
        robots={data.sanityTemplateCategories?.seo?.seo_robots}
        description={data.sanityTemplateCategories?.seo?.description}
        canonical={data.sanityTemplateCategories?.seo?.canonical}
        imageAbsolutePath={data.sanityTemplateCategories?.seo?.thumbnail?.asset?.url}
      />
    );
  }
  return (
    <SEO
      title={data.sanityTemplateCategories?.seo?.title}
      robots={data.sanityTemplateCategories?.seo?.seo_robots}
      description={data.sanityTemplateCategories?.seo?.description}
      canonical={data.sanityTemplateCategories?.seo?.canonical}
      imageAbsolutePath={data.sanityTemplateCategories?.seo?.thumbnail?.asset?.url}
    />
  );
};

export const query = graphql`
  query PageCategories($id: String, $skip: Int!, $limit: Int!) {
    sanityTemplateCategories(id: { eq: $id }) {
      seo {
        title
        seo_robots
        description
        canonical
        thumbnail {
          asset {
            url
          }
        }
      }
      hero {
        title
        photo {
          image {
            asset {
              gatsbyImageData
              _id
            }
          }
          alt
        }
      }
    }
    posts: allSanityTemplatePost(
      skip: $skip
      limit: $limit
      filter: { category: { id: { eq: $id } } }
      sort: { fields: _updatedAt, order: DESC }
    ) {
      nodes {
        _id
        _updatedAt
        publishedAt
        seo {
          description
        }

        slug {
          current
        }
        category {
          name
        }
        text: _rawContent
        hero {
          photo {
            alt
            image {
              asset {
                _id
                gatsbyImageData
              }
            }
          }
          title
        }
      }
    }
    categories: allSanityTemplateCategories(filter: { _id: { ne: $id } }) {
      nodes {
        name
        slug {
          current
        }
      }
    }
    sanityPageHome {
      weKnowYourProblems {
        title
        PAQsList: _rawPaQsList
      }
    }
  }
`;
